const resources = {
  en: {
    translation: {
      Search: 'Search',
      Pages: 'Pages',
      'General Ledger': 'General Ledger',
      'Journal Entries': 'Journal Entries',
      'Cash Transaction': 'Cash Transaction',
      'Bank Transaction': 'Bank Transaction',
      'Transfer Between Accounts': 'Transfer Between Accounts',
      'Foreign Exchange': 'Foreign Exchange',
      'Credit Limit': 'Credit Limit',
      'Account Title': 'Account Title',
      'Account Registration': 'Account Registration',
      Administrator: 'Administrator',
      'Sample Person': 'Moein Rabti',
      Drafts: 'Drafts',
      Title: 'Title',
      English: 'English',
      Alerts: 'Alerts',
      Alert: 'Alert',
      Persian: 'Persian',
      Account: 'Account',
      Documents: 'Documents',
      Reports: 'Reports',
      Settings: 'Settings',
      'Create Customer': 'Create Customer',
      And: 'And',
      Dashboard: 'Dashboard',
      Welcome: 'Welcome',
      'Ariyana Accounting Panel': 'Ariyana Accounting Panel',
      'Ariyana Exchange Accounting Software':
        'Ariyana Exchange Accounting Software',
      'Site under construction or maintenance':
        'Site under construction or maintenance',
      'building not finished yet': 'building not finished yet',
      'First Name': 'First Name',
      'Middle Name': 'Middle Name',
      'Last Name': 'Last Name',
      Gender: 'Gender',
      Undefined: 'Undefined',
      Unknown: 'Unknown',
      Male: 'Male',
      Female: 'Female',
      'Phone Number': 'Phone Number',
      'Language Selection': 'Language Selection',
      'Transfer Owners': 'Transfer Owners',
      Description: 'Description',
      Return: 'Return',
      'Register New Customer': 'Register New Customer',
      'Basic Information': 'Basic Information',
      Colon: ':',
      'Account Number': 'Account Number',
      'Add Account': 'Add Account',
      'Occupation And Personal Details': 'Occupation And Personal Details',
      'Add Address': 'Add Address',
      'Bank Information': 'Bank Information',
      Attachment: 'Attachments',
      'Birth Place Country': 'Brith Place Country',
      Nationality: 'Nationality',
      'Birth Place': 'Birth Place',
      'Birth Date': 'Birth Date',
      Email: 'Email',
      Website: 'Website',
      Occupation: 'Occupation',
      'Other Occupation': 'Other Occupation',
      'Occupation Branch': 'Occupation Branch',
      'Economic Registering Number': 'Economic Registering Number',
      Continue: 'Continue',
      England: 'England',
      Iranian: 'Iranian',
      Programmer: 'Programmer',
      Employee: 'Employee',
      Country: 'Country',
      Province: 'Province',
      City: 'City',
      'Zip Code': 'Zip Code',
      'Exact Address': 'Exact Address',
      Tehran: 'Tehran',
      London: 'London',
      'Currency Type': 'Currency',
      'Transaction Currency': 'Transaction currency',
      'Account Owner Name': 'Account Owner Name',
      'Branch Code': 'Branch Code',
      'Swift Code': 'Swift Code',
      IBAN: 'IBAN',
      Register: 'Register',
      Rial: 'Rial',
      Pond: 'Pond',
      CEO: 'CEO',
      'Document Type': 'Document Type',
      'Document Number': 'Document Number',
      'Expire Date': 'Expire Date',
      'Issue Date': 'Issue Date',
      Profile: 'Profile',
      'Sign Out': 'Sign Out',
      'Document Status': 'Document Status',
      Permanent: 'Permanent',
      Current: 'Current',
      'Type 1': 'Type 1',
      'Type 2': 'Type 2',
      'Drop Your Desired File Here': 'Drop Your Desired File Here',
      Or: 'Or',
      Choose: 'Choose',
      'Valid Formats': 'Valid Formats',
      Application: 'Application',
      'Application Settings': 'Application Settings',
      'Edit Number': 'Edit Number',
      'Connected Account': 'Connected Account',
      'Block Access To Application': 'Block Access To Application',
      'Send Notification Via': 'Send Notification Via',
      SMS: 'SMS',
      Telegram: 'Telegram',
      Whatsapp: 'WhatsApp',
      'Accept And Register': 'Accept And Register',
      Customers: 'Customers',
      Customer: 'Customer',
      List: 'List',
      'Customers List': 'Customers List',
      'Draft Orders List': 'Draft Orders List',
      'Register Customer': 'Register Customer',
      None: 'None',
      'Select Customer': 'Select Customer',
      'Sign in to your account to continue':
        'Sign in to your account to continue',
      'Welcome back': 'Welcome back',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Sign in': 'Sign in',
      'Remember me': 'Remember me',
      'Forgot password': 'Forgot password',
      Ariyana: 'Ariyana',
      'Exchange Accounting Software': 'Exchange Accounting Software',
      'Support And Software Development Panel':
        'Support And Software Development Panel',
      Balance: 'Balance',
      'Sample Balance': '5,435,000',
      'Sample Balance Currency': 'Tomans',
      'Complete Customer Information': 'Complete Customer Information',
      'Next Step': 'Next',
      Sent: 'Sent',
      'Not Sent': 'Not Sent',
      'Customer Information': 'Customer Information',
      All: 'All',
      Currencies: 'Currencies',
      'Draft Submission': 'Draft Submission',
      Branch: 'Branch',
      Sender: 'Sender',
      Receiver: 'Receiver',
      'Draft Currency Type': 'Draft Currency',
      'Company Registration': 'Company Registration',
      'Contact Details': 'Contact Details',
      Address: 'Address',
      Addresses: 'Addresses',
      Shareholders: 'Shareholders',
      'Receivers List': 'Receivers List',
      'Receiver Registration': 'Receiver Registration',
      Accept: 'Accept',
      'Customer Registration': 'Customer Registration',
      'Search By': 'Search by',
      Name: 'Name',
      Comma: ',',
      'Document Prime': 'Document',
      'Advanced Search': 'Advanced Search',
      'Customer Number': 'Customer Number',
      'Customer Name': 'Customer Name',
      Group: 'Group',
      Groups: 'Groups',
      'Father Name': 'Father Name',
      'Account History': 'Account History',
      'Applications Settings': 'Application Settings',
      'Draft Receivers': 'Draft Receivers',
      'Send Message': 'Send Message',
      'Customer Full Details': "Customer's Full Details",
      'Download Customers List Summary': 'Download Customers List Summary',
      'Download Customers Full List': 'Download Customers Full List',
      'Document Expiry Date': 'Document Expiry Date',
      To: 'To',
      Select: 'Select',
      Collateral: 'Collateral‌',
      'Communication Way': 'Communication Way',
      Enable: 'Enable',
      Webcam: 'Webcam',
      Upload: 'Upload',
      Photo: 'Photo',
      'Add New Phone Number': 'Add New Phone Number',
      'At Least One Number Is Mandatory': 'At Least One Number Is Mandatory',
      'Register Customer Without Additional Information': 'Register Customer',
      'Wholesale Exchange': 'Wholesale Exchange',
      'Retail Exchange': 'Retail Exchange',
      Create: 'Create',
      'System Management': 'System Management',
      Business: 'Business',
      'Create Group': 'Create Group',
      'Initial Settings': 'Initial Settings',
      'Header Settings': 'Header Settings',
      'System Settings': 'System Settings',
      'Advanced Settings': 'Advanced Settings',
      Debtor: 'Debtor',
      Creditor: 'Creditor',
      'Create Currency': 'Create Currency',
      Normal: 'Normal',
      Other: 'Other',
      'United States Dollar': 'United States Dollar',
      Dollar: 'Dollar',
      'Account Remaining': 'Account Remaining',
      Remaining: 'Remaining',
      From: 'From',
      Date: 'Date',
      'Balance Currency Type': 'Currency',
      'Mobile Number': 'Phone Number',
      'Account Details': 'Account Details',
      'Banks List': 'Banks list',
      'Banks Management': 'Banks Management',
      Row: 'Row',
      Add: 'Add',
      'New Bank': 'New Bank',
      'Bank Name': 'Bank Name',
      'Card Number': 'Card Number',
      'Bank Branch Code': 'Bank Branch Code',
      Status: 'Status',
      Active: 'Active',
      Archived: 'Archived',
      'Bank Book': 'Bank Book',
      'No Bank Is Registered': 'No Bank Is Registered',
      Bank: 'Bank',
      Edit: 'Edit',
      'Edit Draft': 'Edit Draft',
      'Edit Transaction': 'Edit Transaction',
      'Submit Edit': 'Edit',
      'Archiving The Account': 'Archiving The Account',
      'Cash withdrawal': 'Cash withdrawal',
      'Cash deposit': 'Cash deposit',
      Deposit: 'Deposit',
      Withdraw: 'Withdraw',
      'Search In Ariyana': 'Search In Ariyana',
      'Quick Access': 'Quick Access',
      'Print Information': 'Print',
      'Upload File': 'Upload File',
      'Transaction Description': 'Transaction Description',
      'Receipt Number': 'Receipt Number',
      'Transaction Amount': 'Amount',
      Transaction: 'Transaction',
      Messages: 'Messages',
      Notifications: 'Notifications',
      Languages: 'Languages',
      'Account Balance': 'Account Balance',
      'Cash Desk': 'Cash Desk',
      'System Accounts': 'System Accounts',
      Cash: 'Cash',
      Costs: 'Costs',
      Commission: 'Commission',
      UnknownCreditAccount: 'Unknown Credit Account',
      unknowncreditaccount: 'Unknown Credit Account',
      'Unexecuted draft account': 'Unexecuted Draft Account',
      'unexecuted draft account': 'Unexecuted Draft Account',
      'Account Information': 'Account Information',
      Accounts: 'Accounts',
      ChargingSale: 'Charging Sale',
      UnrealizedDraft: 'Unrealized Draft',
      'Unrealized Drafts': 'Unrealized Drafts',
      UnexecutedDraft: 'Unrealized Drafts',
      BankPayment: 'Bank Payment',
      'No Option Message': 'No Options',
      'Branch Registration': 'Branch Registration',
      'Branch Edition': 'Branch Edition',
      'Advanced Details': 'Advanced Details',
      'Drafts List': 'Drafts List',
      'Draft Documents List': 'Draft Documents List',
      'Unrealized Drafts List': 'Unrealized Drafts List',
      'Branches List': 'Branches List',
      'Branch List': 'Branch List',
      'Trade Currency': 'Trade Currency',
      'Currencies Management': 'Currencies Management',
      'Branch Account': 'Branch Account',
      'Unknown Bank Deposits': 'Unknown Deposit',
      'Submit Cash Transaction': 'Submit Cash Transaction',
      'Submit Bank Transaction': 'Submit Bank Transaction',
      Afghani: 'Afghani',
      Euro: 'Euro',
      Lira: 'Lira',
      'Register Person': 'Register Person',
      'Edit Person': 'Edit Person',
      'Edit Company': 'Edit Company',
      CommonAccount: 'CommonAccount',
      BranchAccount: 'BranchAccount',
      BankAccount: 'BankAccount',
      'Unknown Bank Transactions': 'Unknown Bank Transactions',
      customer: 'Customer',
      receiver: 'Receiver',
      Riyal: 'Riyal',
      Dirham: 'Dirham',
      Rupee: 'Rupee',
      Toman: 'Toman',
      Ruble: 'Ruble',
      'Interbank Transaction': 'Interbank Transaction',
      'Outgoing Draft Submission': 'Outgoing Draft Submission',
      'Incoming Draft Submission': 'Incoming Draft Submission',
      'Draft Conduction': 'Draft Conduction',
      'Cheques List': 'Cheques List',
      SavedToCashDeskOrBankAccount: 'SavedToCashDeskOrBankAccount',
      SavedToCustomerAccount: 'SavedToCustomerAccount',
      HasReturned: 'HasReturned',
      CashedToMoney: 'CashedToMoney',
      'Cashed to money': 'Cashed to money',
      'cashed to money': 'Cashed to money',
      UnknownBankAccounts: 'Unknown Bank Accounts',
      'Profit And Loss Statement': 'Profit And Loss Statement',
      'Payment Order': 'Payment Order',
      profile: 'profile',
      'Operation of the day': 'Operation of the day',
      DocumentIsPrivate: 'Document is private',
      Profit: 'Profit',
      Cost: 'Cost',
      'no attachments': 'no attachments',
      OK: 'OK',
      Cancel: 'Cancel',
      Clear: 'Clear',
      'Select the desired user to migrate to the new system.':
        'Select the desired user to migrate to the new system.',
      'User Account Panel': 'User Account Panel',
      'User Migration': 'User Migration',
      'Sale Report': 'Sale Report',
      'Transaction Status': 'Transaction Status',
      'Services Management': 'Services Management',
      'Daily Health Sale': 'Daily Health Sale',
      'Gardeshgari Bank Bon Card': 'Gardeshgari Bank Bon Card',
      'Buy Charge Packages': 'Buy Charge Packages',
      'Exchanger Rates': 'Exchanger Rates',
      'Registration Of User Migration': 'Registration Of User Migration',
      'Select The Desired User To Migrate To The New System':
        'Select The Desired User To Migrate To The New System',
      'User Name': 'User Name',
      'Add The Code Of The Desired Countries':
        'Add The Code Of The Desired Countries',
      'Country Code': 'Country Code',
      'Add The Desired Currencies And Enter The Currency Code':
        'Add The Desired Currencies And Enter The Currency Code',
      'Currency Name': 'Currency Name',
      'Currency Code': 'Currency Code',
      'System Account Id': 'System Account Id',
      'Add Currency': 'Add Currency',
      'Enter The System Accounts For Verification':
        'Enter The System Accounts For Verification',
      'Account Type': 'Account Type',
      'Account Of Unexecuted Remittances': 'Account Of Unexecuted Remittances',
      'Unknown Bank transaction Account': 'Unknown Bank transaction Account',
      'Credit Account': 'Credit Account',
      'Account Name': 'Account Name',
      'Enter The Database Connection String To Migrate':
        'Enter The Database Connection String To Migrate',
      'Connection String': 'Connection String',
      'Description Of Transactions': 'Description Of Transactions',
      'Submit Information': 'Submit Information',
      'Filling This Field Is Required': 'Filling This Field Is Required',
      'Country Code is Required': 'Country Code is Required',
      'User Name Is Required': 'User Name Is Required',
      'System Account Id Is Required': 'System Account Id Is Required',
      'Connection String is Required': 'Connection String is Required',
      'Date And Time': 'Date And Time',
      'Credit Currency': 'Credit Currency',
      'Credit Amount': 'Credit Amount',
      'Transaction Information': 'Transaction Information',
      'Purchase Credit': 'Purchase Credit',
      'Credit registration': 'Credit registration',
      'Reported Bugs': 'Reported Bugs',
      'My Tickets': 'My Tickets',
      'Exchange Rates': 'Exchange Rates',
      'Users List': 'Users List',
      'Version Type': 'Version Type',
      Operation: 'Operation',
      'Customer Relationship Management': 'Customer Relationship Management',
      'Ticket Details': 'Ticket Details',
      'Account Code': 'Account Code',
      Succeed: 'Succeed',
      Failed: 'Failed',
      Pending: 'Pending',
      // ************************ default document types **************************
      birthCertificate: 'Birth Certificate',
      nationalCard: 'National Card',
      passport: 'Passport',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'transfer between accounts',
      'transaction id': 'transaction id',
      'transaction id:': 'transaction id:',
      'transfer between accounts with': 'transfer between accounts with',
      amount: 'amount',
      debtor: 'debtor',
      creditor: 'creditor',
      'outgoing draft order': 'outgoing draft order',
      'incoming draft order': 'incoming draft order',
      'Miscellaneous Draft Submission': 'miscellaneous draft order',
      to: 'to',
      from: 'from',
      at: 'at',
      'was registered.': 'was registered.',
      'commission for': 'commission for',
      'bank account': 'bank account',
      'customer account': 'customer account',
      'sell currency': 'sell currency',
      'buy currency': 'buy currency',
      'bank transaction': 'bank transaction',
      commission: 'commission',
      cash: 'cash',
      costs: 'costs',
      'cash transaction': 'cash transaction',
      unrealizeddraft: 'unrealized draft',
      unexecuteddraft: 'unrealized draft',
      unknownbankaccounts: 'unknown bank accounts',
      chargingsale: 'charging sale',
      'buy charge': 'buy charge',
      'Renewal Version': 'Renewal Version',
      'Active WhatsApp Access': 'Active WhatsApp Access',
      'Deactivate WhatsApp Access': 'Deactivate WhatsApp Access',
      'Payment Term': 'Payment Term',
      'Payment Method': 'Payment Method',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'Afghanistan',
      Australia: 'Australia',
      China: 'China',
      Denmark: 'Denmark',
      Germany: 'Germany',
      Greece: 'Greece',
      Iran: 'Iran',
      Iraq: 'Iraq',
      Norway: 'Norway',
      Pakistan: 'Pakistan',
      'Saudi Arabia': 'Saudi Arabia',
      Sweden: 'Sweden',
      Turkey: 'Turkey',
      'United Arab Emirates': 'United Arab Emirates',
      Baghdad: 'Baghdad',
      Ankara: 'Ankara',
      Kabul: 'Kabul',
      'Islamabad, Karachi': 'Islamabad, Karachi',
      // ***********************************
      // end countries
      // ***********************************
      // ***********************************
      // actionTypes
      // ***********************************
      TransferBetweenTwoAccounts: 'Transfer Between Accounts',
      Cheque: 'Cheque',
      CashTransaction: 'Cash Transaction',
      IncomingDraft: 'Incoming Draft',
      OutgoingDraft: 'Outgoing Draft',
      Miscellaneous: 'Miscellaneous Draft',
      BuySellCurrency: 'Trade Currency',
      BankTransaction: 'Bank Transaction',
      InterBankTransaction: 'Inter Bank Transaction',
      PaymentOrderReceipt: 'Payment Order Receipt',
      ExecuteDraft: 'Execute Draft',
      basicRegistered: 'basicRegistered',
      expired: 'expired',
      paid: 'paid',
      paying: 'paying',
      Weak: 'Weak',
      Medium: 'Medium',
      Good: 'Good',
      Excellent: 'Excellent'
      // ***********************************
      // end actionTypes
      // ***********************************
    }
  },
  fa: {
    translation: {
      dir: 'rtl',
      Search: 'جستجو',
      Pages: 'صفحات',
      Alerts: 'اطلاع رسانی',
      Alert: 'اعلان',
      Transaction: 'تراکنش',
      'General Ledger': 'دفتر کل',
      'Journal Entries': 'دفتر روزنامه',
      'Cash Transaction': 'تراکنش نقدی',
      'Bank Transaction': 'تراکنش بانکی',
      'Transfer Between Accounts': 'انتقال بین حساب',
      'Foreign Exchange': 'خرید و فروش ارز',
      'Sample Person': 'معین ربطی',
      'Credit Limit': 'سقف اعتبار',
      'Account Title': 'عنوان حساب',
      'Account Registration': 'افتتاح حساب',
      'Create Customer': 'ثبت مشتری جدید',
      'Site under construction or maintenance': 'در حال تولید هستیم ...',
      'building not finished yet': 'محتوای این قسمت هنوز آماده نیست :)',
      And: 'و',
      English: 'انگلیسی',
      Persian: 'فارسی',
      Title: 'عنوان',
      Account: 'حساب',
      Drafts: 'حواله ها',
      Draft: 'حواله',
      Equal: 'معادل',
      Documents: 'مدارک',
      Reports: 'گزارشات',
      Settings: 'تنظیمات',
      Administrator: 'مدیر سیستم',
      Dashboard: 'داشبورد',
      Welcome: 'خوش آمدید',
      'Moneyx Accounting Panel': 'پنل حسابداری مانیکس',
      'Moneyx Exchange Accounting Software':
        'نرم افزار حسابداری حسابداری مانیکس ',
      'First Name': 'نام',
      'Middle Name': 'نام میانی',
      'Last Name': 'نام خانوادگی',
      Gender: 'جنسیت',
      Undefined: 'نامعلوم',
      Unknown: 'نامعلوم',
      Male: 'مرد',
      Female: 'زن',
      'Phone Number': 'شماره تماس',
      'Language Selection': 'انتخاب زبان',
      'Transfer Owners': 'حواله داران',
      Description: 'توضیحات',
      Return: 'بازگشت',
      'Register New Customer': 'ثبت مشتری جدید',
      'Basic Information': 'اطلاعات اولیه',
      Colon: ':',
      'Account Number': 'شماره حساب',
      'Add Account': 'افزودن حساب',
      'Occupation And Personal Details': 'اطلاعات فردی و شغلی',
      'Add Address': 'افزودن آدرس',
      'Bank Information': 'اطلاعات بانک',
      Attachment: 'ضمیمه',
      'Birth Place Country': 'کشور محل تولد',
      Nationality: 'ملیت',
      'Birth Place': 'محل تولد',
      'Birth Date': 'تاریخ تولد',
      Email: 'ایمیل',
      Website: 'وب سایت',
      Occupation: 'شغل',
      'Other Occupation': 'عنوان شغل (در صورت انتخاب سایر)',
      'Occupation Branch': 'نوع کار',
      'Economic Registering Number': 'کد اقتصادی',
      Continue: 'ادامه',
      England: 'انگلیس',
      Iranian: 'ایرانی',
      Programmer: 'برنامه نویس',
      Employee: 'کارمند',
      Country: 'کشور',
      Province: 'استان',
      City: 'شهر',
      'Zip Code': 'کد پستی',
      'Exact Address': 'نشانی دقیق',
      Tehran: 'تهران',
      London: 'لندن',
      'Currency Type': 'نوع ارز',
      'Account Owner Name': 'نام صاحب حساب',
      'Account Owner': 'صاحب حساب',
      'Total Deposit Amount': 'جمع مبلغ واریز',
      'Branch Code': 'کد شعبه',
      'Swift Code': 'کد سوئیفت',
      IBAN: 'شماره شبا',
      Register: 'ثبت',
      Rial: 'ریال',
      Pond: 'پوند',
      CEO: 'مدیرعامل',
      'Document Type': 'نوع مدرک',
      'Document Number': 'شماره مدرک',
      'Expire Date': 'تاریخ انقضا',
      'Issue Date': 'تاریخ صدور',
      Profile: 'پروفایل',
      'Sign Out': 'خروج',
      'Document Status': 'وضعیت مدرک',
      Permanent: 'دائمی',
      Current: 'فعلی',
      'Type 1': 'نوع اول',
      'Type 2': 'نوع دوم',
      'Drop Your Desired File Here': 'فایل مورد نظر را اینجا رها کنید',
      Or: 'یا',
      Choose: 'انتخاب کنید',
      'Valid Formats': 'فرمت‌های مجاز',
      Application: 'اپلیکیشن',
      'Application Settings': 'تنظیمات اپلیکیشن',
      'Edit Number': 'ویرایش شماره',
      'Connected Account': 'حساب متصل',
      'Block Access To Application': 'بلاک کردن دسترسی به اپلیکیشن',
      'Send Notification Via': 'ارسال نوتیفیکیشن از طریق',
      SMS: 'پیامک',
      Telegram: 'تلگرام',
      Whatsapp: 'واتساپ',
      'Accept And Register': 'تایید و ثبت',
      Customers: 'مشتریان',
      'Customers List': 'لیست مشتریان',
      'Draft Orders List': 'لیست سفارشات حواله',
      Customer: 'مشتری',
      List: 'لیست',
      'Register Customer': 'ثبت مشتری',
      'Select Customer': 'انتخاب مشتری',
      'Sign in to your account to continue': 'بر ادامه لطفاً وارد شوید.',
      'Welcome back': 'خوش آمدید',
      None: 'نامشخص',
      'Email Address': 'آدرس ایمیل',
      Password: 'رمز عبور',
      'Sign in': 'ورود',
      'Remember me': 'مرا به خاطر بسپار',
      'Forgot password': 'پسوردم فراموش شده!',
      Use: 'استفاده کنید',
      and: 'و',
      'to sign in': 'برای ورود.',
      Moneyx: 'مانیکس',
      'Exchange Accounting Software': 'نرم افزار حسابداری صرافی',
      Balance: 'باقیمانده',
      'Sample Balance': '5/435/000',
      'Sample Balance Currency': 'تومان',
      'Complete Customer Information': 'تکمیل اطلاعات مشتری',
      'Next Step': 'مرحله بعدی',
      Sent: 'ارسال شده',
      'Not Sent': 'ارسال نشده',
      'Customer Information': 'اطلاعات مشتری',
      All: 'همه',
      Currencies: 'ارزها',
      'Draft Submission': 'ثبت حواله',
      Branch: 'نمایندگی',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      'Draft Currency Type': 'نوع ارز حواله',
      'Company Registration': 'ثبت شرکت',
      'Contact Details': 'اطلاعات تماس',
      Address: 'آدرس',
      Addresses: 'آدرس‌ها',
      Shareholders: 'سهام‌داران',
      'Add/Update Shareholders': 'افزودن / ویرایش سهام‌دار',
      'Receivers List': 'لیست گیرنده‌ها',
      'Receiver Registration': 'ثبت گیرنده',
      Accept: 'تایید',
      'Customer Registration': 'ثبت مشتری',
      'Search By': 'جستجو با',
      Name: 'نام',
      Comma: '،',
      'Document Prime': 'مدرک',
      'Advanced Search': 'جستجوی پیشرفته',
      'Customer Number': 'شماره مشتری',
      'Customer Name': 'نام مشتری',
      Group: 'گروه',
      Groups: 'گروه‌ها',
      'Father Name': 'نام پدر',
      'Account History': 'تاریخچه حساب',
      'Applications Settings': 'تنظیمات برنامه‌ها',
      'Draft Receivers': 'گیرنده‌های حواله',
      'Send Message': 'ارسال پیام',
      'Customer Full Details': 'اطلاعات کامل مشتری',
      'Download Customers List Summary': 'دانلود لیست با خلاصه مشتریان',
      'Download Customers Full List': 'دانلود لیست با مشخصات کامل',
      'Document Expiry Date': 'تاریخ انقضاء مدرک',
      To: 'تا',
      Select: 'انتخاب',
      Collateral: 'معرف',
      'Communication Way': 'نوع تماس با شماره',
      Enable: 'فعالسازی',
      Webcam: 'وبکم',
      Upload: 'آپلود',
      Photo: 'عکس',
      'Add New Phone Number': 'افزودن شماره جدید',
      'At Least One Number Is Mandatory': 'حداقل یک شماره اجباری است',
      'Phone Number Cannot Be Repeated.': 'شماره تلفن نمی توانید تکراری باشد.',
      'Register Customer Without Additional Information':
        'ثبت شخص بدون اطلاعات تکمیلی',
      'Wholesale Exchange': 'حواله‌دار عمده',
      'Retail Exchange': 'حواله‌دار خرده',
      Create: 'ایجاد',
      'System Management': 'مدیریت سامانه',
      Business: 'کسب و کار',
      'Create Group': 'ایجاد گروه',
      'System Settings': 'تنظیمات سامانه',
      'Advanced Settings': 'تنظیمات پیشرفته',
      'Initial Settings': 'تنظیمات اولیه',
      'Header Settings': 'تنظیمات سربرگ',
      'Add Currency': 'افزودن ارز',
      'Update Currency': 'ویرایش ارز',
      'Upload a logo for the header': 'آپلود لوگو برای سربرگ',
      Debtor: 'بدهکار',
      Creditor: 'طلبکار',
      'Create Currency': 'ایجاد ارز',
      Normal: 'عادی',
      Other: 'سایر',
      Dollar: 'دلار',
      'Account Remaining': 'باقیمانده حساب',
      Remaining: 'باقیمانده',
      From: 'از',
      Date: 'تاریخ',
      'Balance Currency Type': 'نوع ارز موجودی',
      'Mobile Number': 'شماره موبایل',
      'Account Details': 'اطلاعات حساب',
      'Banks List': 'لیست بانک‌ها',
      'Banks Management': 'مدیریت بانک‌ها',
      Row: 'ردیف',
      Add: 'افزودن',
      'New Bank': 'بانک جدید',
      'Bank Name': 'نام بانک',
      'Card Number': 'شماره کارت',
      'Bank Branch Code': 'کد شعبه',
      Status: 'وضعیت',
      Active: 'فعال',
      Archived: 'بایگانی',
      'Bank Book': 'دفتر بانک',
      'No Bank Is Registered': 'هیچ بانکی ثبت نشده است',
      'No Submited Transaction': 'تراکنشی ثبت نشده است',
      Bank: 'بانک',
      Edit: 'ویرایش',
      IncreaseCredit: 'افزایش اعتبار',
      CancelDraft: 'لغو حواله',
      'Edit Draft': 'ویرایش حواله',
      'Edit Transaction': 'ویرایش تراکنش',
      'Edit Bank Transaction': 'ویرایش تراکنش بانکی',
      'Submit Edit': 'ثبت ویرایش',
      'Archiving The Account': 'بایگانی کردن حساب',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      Deposit: 'دریافت',
      Withdraw: 'پرداخت',
      'Search In Moneyx': 'جستجو در مانیکس',
      'Quick Access': 'دسترسی سریع',
      'Print Information': 'چاپ اطلاعات',
      'Upload File': 'آپلود فایل',
      'Transaction Description': 'شرح تراکنش',
      'Receipt Number': 'شماره فیش',
      'Transaction Amount': 'مبلغ تراکنش',
      Messages: 'پیام‌ها',
      Notifications: 'اعلان‌ها',
      Languages: 'زبان‌ها',
      'Account Balance': 'موجودی حساب',
      'Cash Desk': 'صندوق',
      'System Accounts': 'حساب‌های سامانه',
      Cash: 'صندوق',
      Costs: 'هزینه‌ها',
      Commission: 'کارمزد',
      creditaccount: 'حساب اعتبارات',
      UnknownCreditAccount: 'اعتبار نامعلوم',
      unknowncreditaccount: 'اعتبار نامعلوم',
      'Unexecuted draft account': 'حواله‌های اجرا نشده',
      'unexecuted draft account': 'حواله‌های اجرا نشده',
      'Account Information': 'اطلاعات حساب',
      Accounts: 'حساب‌ها',
      ChargingSale: 'فروش شارژ',
      UnrealizedDraft: 'حواله‌های اجرا نشده',
      'Unrealized Drafts': 'حواله‌های اجرا نشده',
      UnexecutedDraft: 'حواله‌های اجرا نشده',
      BankPayment: 'دریافت و پرداخت بانکی',
      'No Option Message': 'موردی وجود ندارد',
      'Branch Registration': 'ثبت نمایندگی',
      'Branch Edition': 'ویرایش نمایندگی',
      'Advanced Details': 'اطلاعات تکمیلی',
      'Drafts List': 'لیست حواله‌ها',
      'Draft Documents List': 'لیست اسناد حواله',
      'Unrealized Drafts List': 'لیست حواله‌های اجرا نشده',
      'Pending Drafts List': 'لیست حواله های در انتظار',
      'Branches List': 'لیست نمایندگی‌ها',
      'Branch List': 'لیست نمایندگی‌',
      'Trade Currency': 'خرید و فروش ارز',
      'Currencies Management': 'مدیریت ارزها',
      'Branch Account': 'حساب نمایندگی',
      'Credits Account': 'حساب اعتبارات',
      'Customer Credit Account': 'حساب اعتبار مشتری',
      'Unknown Bank Deposits': 'واریزهای نامعلوم بانکی',
      'Submit Cash Transaction': 'ثبت تراکنش نقدی',
      'Submit Bank Transaction': 'ثبت تراکنش بانکی',
      'New Submit Bank Transaction': 'ثبت تراکنش بانکی (جدید)',
      'Register Person': 'ثبت شخص',
      'Edit Person': 'ویرایش شخص',
      'Edit Company': 'ویرایش شرکت',
      CommonAccount: 'حساب',
      BranchAccount: 'نمایندگی',
      BankAccount: 'بانک',
      Operation: 'عملیات',
      'Operation Type': 'نوع عملیات',
      'Unknown Bank Transactions': 'تراکنش‌های نامعلوم بانکی',
      customer: 'مشتری',
      receiver: 'گیرنده',
      'Interbank Transaction': 'تراکنش بین بانکی',
      'Outgoing Draft Submission': 'ثبت حواله رفت',
      'Incoming Draft Submission': 'ثبت حواله آمد',
      'Miscellaneous Draft Submission': 'ثبت حواله متفرقه',
      'Draft Conduction': 'اجرای حواله',
      'Cheques List': 'لیست چک‌ها',
      'Debtor And Creditor List': 'لیست بدهکار و طلبکار',
      'Commissions Report': 'گزارش کارمزد ها',
      'Trade Currencies List': 'لیست خرید و فروش ارز',
      SavedToCashDeskOrBankAccount: 'خوابانده شده',
      SavedToCustomerAccount: 'واگذار شده',
      HasReturned: 'برگشت خورده',
      CashedToMoney: 'پاس شده',
      'Cashed to money': 'پاس شده',
      'cashed to money': 'پاس شده',
      UnknownBankAccounts: 'تراکنش‌های نامعلوم بانکی',
      'Profit And Loss Statement': 'صورت سود و زیان',
      'Calculating Profit and Loss': 'محاسبه‌گر سود و زیان',
      'Payment Order': 'دستور پرداخت',
      profile: 'حساب کاربری',
      'Operation of the day': 'عملیات روز',
      DocumentIsPrivate: 'سند بدون ثبت توضیحات است',
      Profit: 'سود',
      Loss: 'ضرر',
      Cost: 'هزینه',
      Delete: 'حذف',
      'no attachments': 'ضمیمه ندارد',
      'The Entered Amount Is Bigger Than The Sum Of The Payment Order':
        'مبلغ وارد شده، از جمع مقدار مبالغ فیش بالاتر می باشد',
      OK: 'تایید',
      Cancel: 'انصراف',
      Clear: 'پاک کردن',
      'System Guides': 'راهنما کار با سامانه',
      'System Main Currency': 'ارز پیشفرض سامانه',
      'Increase Customer Credit': 'افزایش اعتبار مشتری',
      'Date And Time': 'تاریخ و زمان',
      'Credit Currency': 'ارز اعتبار',
      'Credit Amount': 'مبلغ اعتبار',
      'Transaction Information': 'اطلاعات تراکنش',
      'Credit Registration': 'ثبت اعتبار',
      'Reported Bugs': 'خطاهای گزارش شده',
      'Excel Data Output': 'خروجی اکسل اطلاعات',
      'Bug Details': 'جزئیات خطا',
      'My Tickets': 'تیکت‌های من',
      'From Date': 'از تاریخ',
      'To Date': 'تا تاریخ',
      Failed: 'رد شده',
      Pend: 'در انتظار',
      Success: 'تایید شده',
      'Request Status': 'وضعیت درخواست',
      'Pending Transactions List': 'لیست تراکنش‌های در انتظار',
      'Submitted By Employee': 'ثبت شده توسط کارمند',
      'Submitted By Customer': 'ثبت شده توسط مشتری',
      'Submitted By Mirror Branch': 'ثبت شده توسط نمایندگی مقابل',
      Number: 'شماره',
      'From Number': 'از شماره',
      'To Number': 'تا شماره',
      Submit: 'تایید',
      Reject: 'رد',
      'Remove Filters': 'حذف فیلترها',
      'Creditor Customer': 'مشتری طلبکار',
      'Debtor Customer': 'مشتری بدهکار',
      Recieve: 'دریافت',
      'Bank Transaction Is Confidential': 'تراکنش بانکی بدون ثبت توضیحات باشد',
      'Transaction Currency': 'ارز تراکنش',
      'Commission Amount': 'مبلغ کارمزد',
      'Is There A Fee For This Transaction': 'این تراکنش کارمزد دارد؟',
      Incoming: 'آمد',
      Outgoing: 'رفت',
      'Account Name': 'نام حساب',
      Type: 'نوع',
      'Visit Account': 'مشاهده حساب',
      'Checkout History': 'تاریخچه تسویه',
      'Cheque History': 'تاریخچه چک',
      'Upload Excel File': 'آپلود فایل اکسل',
      'Information Output': 'خروجی اطلاعات',
      'Normal Print': 'چاپ عادی',
      'Private Print': 'چاپ بدون ثبت توضیحات',
      'Date Of Payment Order': 'تاریخ دستور پرداخت',
      'Transaction Date': 'تاریخ تراکنش',
      'Transaction Number': 'شماره تراکنش',
      'Buy Currency': 'ارز خرید',
      'Sell Currency': 'ارز فروش',
      'Buy Commission': 'کارمزد خرید',
      'Sell Commission': 'کارمزد فروش',
      '(Optional)': '(اختیاری)',
      'Buy Currency Setting': 'تنظیمات خرید ارز',
      'Sell Currency Setting': 'تنظیمات فروش ارز',
      'Upload Documents': 'آپلود مدارک',
      Private: 'بدون ثبت توضیحات باشه',
      'Private Transaction': 'تراکنش بدون ثبت توضیحات باشد',
      'Not Private': 'بدون ثبت توضیحات نباشه',
      'Not Change The Credit Limit': 'سقف اعتبار تغییر نکنه',
      'Change The Credit Limit': 'سقف اعتبار تغییر میکنه',
      'Confirm Buy': 'ثبت خرید',
      'Trade Rate': 'نرخ تبدیل',
      'Buy Amount': 'مبلغ خرید',
      'Sell Amount': 'مبلغ فروش',
      'Seller Commission': 'کارمزد فروشنده',
      'Buyer Commission': 'کارمزد خریدار',
      Buyer: 'خریدار',
      Seller: 'فروشنده',
      'Draft Number': 'شماره حواله',
      'Incoming Draft Number': 'شماره حواله آمد',
      'Outgoing Draft Number': 'شماره حواله رفت',
      Confirm: 'تایید',
      'Draft Cancellation': 'لغو حواله',
      'Transaction Type': 'نوع تراکنش',
      System: 'سامانه',
      'System Account': 'حساب سامانه',
      'Customer Commission': 'کارمزد مشتری',
      'Branch Commission': 'کارمزد نمایندگی',
      'Transaction Status': 'وضعیت تراکنش',
      'Settings And File Upload': 'تنظیمات و آپلود فایل',
      'Other Number': 'شماره متفرقه',
      'Debtor Commission': 'کارمزد بدهکار',
      'Creditor Commission': 'کارمزد طلبکار',
      'Debtor Account': 'حساب بدهکار',
      'Creditor Account': 'حساب طلبکار',
      'Commission Currency': 'ارز کارمزد',
      'Edit Miscellaneous Draft': 'ویرایش حواله متفرقه',
      'Spot Rate': 'نرخ لحظه‌ای',
      'Source Of Money': 'منبع پول',
      'Purpose Of Exchange': 'علت معامله',
      'Periodic Profit Chart': 'نمودار دوره ای سود',
      'Daily Transactions Chart': 'نمودار تراکنش های روزانه',
      'Organization Chart': 'چارت سازمانی',
      'Draft List': 'لیست حواله',
      'Birth Certificate': 'شناسنامه',
      'National Card': 'کارت ملی',
      Passport: 'گذرنامه',
      'Physical Tezkire': 'تذکره کاغذی',
      'Electronic Tezkire': 'تذکره برقی',
      'Kimlik Card': 'کارت کیملیک',
      'Driving License': 'گواهینامه',
      'Temporary Residence Card Of Foreign Nationals': 'کارت آمایش',
      Police: 'پلیس',
      'Civil Registry Office': 'اداره ثبت احوال',
      'Immigration Office': 'اداره مهاجرت',
      'Live Currency Rate': 'نرخ لحظه ای ارز',
      'Due to the long number of letters of the selected document information, it is not possible to share.':
        'نظر به طولانی بودن تعداد حروف اطلاعات سند انتخابی، امکان اشتراک گذاری فراهم نیست.',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'انتقال بین حساب',
      'transaction id': 'شماره تراکنش',
      'transaction id:': 'شماره تراکنش:',
      'transfer between accounts with': 'انتقال بین حساب با',
      amount: 'مبلغ',
      currency: 'ارز',
      debtor: 'بدهکار',
      creditor: 'طلبکار',
      Percent: 'درصد',
      Liquidity: 'نقدینگی',
      'outgoing draft order': 'حواله رفت',
      'incoming draft order': 'حواله آمد',
      'Update Check Rate': 'ویرایش نرخ حواله',
      'Add Payment Place': 'افزودن محل پرداخت',
      'Payment Place': 'محل پرداخت',
      to: 'به',
      from: 'از',
      at: 'در',
      'was registered.': 'ثبت شد.',
      'commission for': 'کارمزد',
      'bank account': 'حساب بانک',
      'customer account': 'حساب مشتری',
      'sell currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'bank transaction': 'تراکنش بانکی',
      commission: 'کارمزد',
      'cash transaction': 'تراکنش نقدی',
      unrealizeddraft: 'حواله‌های اجرا نشده',
      unexecuteddraft: 'حواله‌های اجرا نشده',
      unknownbankaccounts: 'تراکنش‌های نامعلوم بانکی',
      chargingsale: 'فروش شارژ',
      'buy charge': 'خرید شارژ',
      cash: 'صندوق',
      costs: 'هزینه‌ها',
      Documentations: 'مستندات',
      Others: 'متفرقه',
      'Undo Cancellation': 'بازگرداندن لغو',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'افغانستان',
      Australia: 'استرالیا',
      China: 'چین',
      Denmark: 'دانمارک',
      Germany: 'آلمان',
      Greece: 'یونان',
      Iran: 'ایران',
      Iraq: 'عراق',
      Norway: 'نروژ',
      Pakistan: 'پاکستان',
      'Saudi Arabia': 'عربستان سعودی',
      Sweden: 'سوئد',
      Turkey: 'ترکیه',
      'United Arab Emirates': 'امارات متحده عربی',
      Baghdad: 'بغداد',
      Ankara: 'انکارا',
      Kabul: 'کابل',
      'Islamabad, Karachi': 'کراچی ,اسلام آباد',
      // ***********************************
      // end countries
      // ***********************************
      // ***********************************
      // actionTypes
      // ***********************************
      TransferBetweenTwoAccounts: 'انتقال بین حساب',
      Cheque: 'چک',
      CashTransaction: 'تراکنش نقدی',
      IncomingDraft: 'حواله آمد',
      OutgoingDraft: 'حواله رفت',
      Miscellaneous: 'متفرقه',
      BuySellCurrency: 'خرید و فروش ارز',
      BankTransaction: 'تراکنش بانکی',
      RemoveCheckout: 'حذف تسویه',
      PendDraft: 'حواله در انتظار',
      RemovePendDraft: 'حذف حواله در انتظار',
      ExecuteDraft: 'اجرای حواله',
      InterBankTransaction: 'تراکنش بین بانکی',
      Checkout: 'تسویه',
      PaymentOrderReceipt: 'رسید دستور پرداخت',
      ChangeBranch: 'تبدیل نرخ',
      UnknownBankTransaction: 'تراکنش نامعلوم بانکی',
      AddAccount: 'افزودن حساب',
      UpdateAccount: 'ویرایش حساب',
      DeleteAccount: 'حذف حساب',
      AddBank: 'افزودن بانک',
      UpdateBank: 'ویرایش بانک',
      DeleteBank: 'حذف بانک',
      AddCustomer: 'افزودن مشتری',
      UpdateCustomer: 'ویرایش مشتری',
      DeleteCustomer: 'حذف مشتری',
      AddCompany: 'افزودن شرکت',
      UpdateCompany: 'ویرایش شرکت',
      DeleteCompany: 'حذف شرکت',
      AddBranch: 'افزودن نمایندگی',
      UpdateBranch: 'ویرایش نمایندگی',
      DeleteBranch: 'حذف نمایندگی',
      ConnectToBranch: 'اتصال به نمایندگی',
      OneWayConnecting: 'اتصال یک طرفه',
      BranchName: 'نام نمایندگی',
      BranchManager: 'مسئول نمایندگی',
      BranchIsNotFound: 'نمایندگی یافت نشد',
      SelecTheDesiredBranch: 'نمایندگی مورد نظر را انتخاب کنید',
      InOneWayConnectionModeTheCounterpartyIsNotAblleToSeeYoureTransactions:
        'در حالت اتصال یک طرفه نمایندگی مقابل قادر به مشاهده تراکنش های شما نیست',
      AddCurrency: 'افزودن ارز',
      UpdateCurrency: 'ویرایش ارز',
      DeleteCurrency: 'حذف ارز',
      AddPayOrder: 'افزودن دستور پرداخت',
      UpdatePayOrder: 'ویرایش دستور پرداخت',
      DeletePayOrder: 'حذف دستور پرداخت',
      PublishTransactionInWhatsapp: 'اعلان تراکنش در واتساپ',
      ShouldHaveSamevaluein: 'باید دارای مقادیر یکسان باشند در',
      'In progress': 'در حال پردازش',
      'Payment Term': 'شرایط پرداخت',
      'Payment Method': 'شیوه پرداخت',
      'User Migration': 'مهاجرت کاربران',
      'Exchange Rates': 'نرخ ارز ها',
      'Users List': 'لیست کاربران',
      'Sale Report': 'گزارش فروش',
      'Services Management': 'مدیریت خدمات',
      'Daily Health Sale': 'فروش روزانه',
      basicRegistered: 'ثبت نام اولیه',
      expired: 'انقضای کاربری',
      paid: 'تسویه شده',
      paying: 'در حال پرداخت',
      'Customer Relationship Management': 'مدیریت ارتباط کاربران',
      Weak: 'ضعیف',
      Medium: 'متوسط',
      Good: 'خوب',
      Excellent: 'عالی'
      // ***********************************
      // end actionTypes
      // ***********************************
    },
    dir: 'rtl'
  }
};

export default resources;
